import { PVector } from './p-vector'
import { PALEYELLOW, PINKRED } from './colors'
import { Color } from './color'
import { sigmoid } from './math-helper'

export class Comet {
  position
  velocity
  maxRadius = 10
  length = 40
  alive = true
  trail = []
  canvas: HTMLCanvasElement
  ctx: CanvasRenderingContext2D

  constructor(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) {
    this.canvas = canvas
    this.ctx = ctx
    let x, y, xVel, yVel

    if (Math.random() > 0.5) {
      x = Math.random() * this.canvas.width
      y = -this.maxRadius
    } else {
      x = this.canvas.width + this.maxRadius
      y = Math.random() * this.canvas.height
    }

    xVel = -(Math.random() * 1 + 3)
    yVel = Math.random() * 1 + 3

    this.position = new PVector(x, y)
    this.velocity = new PVector(xVel, yVel)
  }

  checkAlive() {
    if (this.trail[this.trail.length - 1].x < 0 || this.trail[this.trail.length - 1].y > this.canvas.height) {
      this.alive = false
    }
  }

  draw() {
    this.ctx.beginPath()
    this.ctx.arc(this.position.x, this.position.y, this.maxRadius, 0, 2 * Math.PI)
    this.ctx.fillStyle = `rgb(${PALEYELLOW.r}, ${PALEYELLOW.g}, ${PALEYELLOW.b})`
    this.ctx.fill()
    this.ctx.closePath()

    for (let i = 0; i < this.trail.length; i++) {
      const ratio = i / (this.trail.length - 1)
      const color = Color.lerpColor(PALEYELLOW, PINKRED, sigmoid(ratio))
      const radius = (1 - ratio) * this.maxRadius

      this.ctx.beginPath()
      this.ctx.arc(this.trail[i].x, this.trail[i].y, radius, 0, 2 * Math.PI)
      this.ctx.fillStyle = `rgb(${color.r}, ${color.g}, ${color.b})`
      this.ctx.globalAlpha = .6 * (1 - ratio)
      this.ctx.fill()
      this.ctx.globalAlpha = 1
      this.ctx.closePath()
    }
  }

  update() {
    this.draw()

    this.trail.unshift(this.position)
    if (this.trail.length > this.length) {
      this.trail.pop()
    }

    this.position = this.position.add(this.velocity)

    this.checkAlive()
  }
}
