import { Color } from './color'

export const NAVYBLUE = new Color(26, 24, 67);
export const PINK = new Color(207, 98, 140);
export const REDPINK = new Color(98, 52, 108);
export const LIGHTBLUE = new Color(92, 104, 212);
export const PALEYELLOW = new Color(231, 210, 124);
export const PINKRED = new Color(225, 66, 99);
export const PALENAVYBLUE = new Color(78, 65, 100);
export const PURPLE = new Color(129, 54, 139);
export const PURPLEGRAY = new Color(121, 90, 138);
export const CHARCOAL = new Color(70, 70, 70);
export const WHITE = new Color(255, 255, 255);
