import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'

@Injectable()
export class HttpService {

  constructor(private http: HttpClient) { }

  get(endpoint: string): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}api/${endpoint}`)
      .pipe(take(1))
  }
}
