import { Component, Input, OnInit } from '@angular/core'
import { Attachment } from '../../../../models/calendar-event'

const imageExtensions = [
  'jpg',
  'jpeg',
  'gif',
  'png',
]

const videoExtensions = [
  'mp4',
  'wmv',
  'mov',
  'avi',
]

const documentExtensions = [
  'pdf',
  'doc',
  'docx',
  'rtf',
  'odt',
  'tex',
  'md',
]

const sourceCodeExtensions = [
  'c',
  'h',
  'cpp',
  'hpp',
  'cs',
  'fs',
  'java',
  'swift',
  'vb',
  'html',
  'haml',
  'slim',
  'css',
  'scss',
  'sass',
  'less',
  'json',
  'py',
  'rb',
  'rs',
  'ex',
  'exs',
  'js',
  'ts',
  'php',
  'sql',
  'groovy',
  'go',
  'r',
  'd',
  'dart',
  'scala',
  'lua',
  'clj',
  'hs',
  'scm',
  'kt',
  'erl',
]

const spreadsheetExtensions = [
  'xls',
  'xlsx',
  'csv',
  'dat',
  'xml',
]

@Component({
  selector: 'event-attachment',
  templateUrl: './event-attachment.component.html',
  styleUrls: ['./event-attachment.component.scss']
})
export class EventAttachmentComponent implements OnInit {
  @Input() attachment: Attachment
  attachmentTitle: string

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.attachmentTitle = this.formatTitle(this.attachment.title, 12)
  }

  get icon(): string {
    const basePath = '../../../../../assets/icons/'

    const extension = this.getExtension()

    if (imageExtensions.includes(extension))
      return basePath + 'Img.svg'
    else if (videoExtensions.includes(extension))
      return basePath + 'Video.svg'
    else if (documentExtensions.includes(extension))
      return basePath + 'Document.svg'
    else if (spreadsheetExtensions.includes(extension))
      return basePath + 'SpreadSheet.svg'
    else if (sourceCodeExtensions.includes(extension))
      return basePath + 'Code.svg'
    else
      return basePath + 'Clip.svg'
  }

  private getExtension(): string {
    return this.attachment.title.split('.').pop()
  }

  formatTitle(title, length) {
    if (title.length > length)
      return `${title.substring(0, length - 3)}...`

    return title
  }

  viewAttachment() {
    window.open(this.attachment.fileUrl)
  }

}
