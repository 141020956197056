import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { CalendarEvent } from '../../../models/calendar-event'

@Component({
  selector: 'app-event-description-mobile',
  templateUrl: './event-description-mobile.component.html',
  styleUrls: ['./event-description-mobile.component.scss']
})
export class EventDescriptionMobileComponent implements OnInit {
  event: CalendarEvent

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.event = JSON.parse(params['event'])
    })
  }

}
