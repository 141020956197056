import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { CalendarEvent } from '../../../models/calendar-event'
import { HttpService } from '../../../services/http.service'
import * as moment from 'moment'
import { Moment } from 'moment'


@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  @ViewChild('calendar') calendar
  @Input() events: CalendarEvent[]
  @Input() selectedDay: Moment
  @Output() changeDay = new EventEmitter<Moment>()
  @Output() changeMonth = new EventEmitter<String>()

  today: Moment = moment()
  daysArr: Moment[]
  count : number [] = []

  constructor(private http: HttpService) {
  }

  ngOnInit() {
    this.daysArr = this.createCalendar(this.today)
  }

  ngAfterViewInit() {
    this.resizeCalendar()
  }

  changeSelectedDay(day: Moment) {
    this.changeDay.emit(day)
  }

  changeSelectedMonth(direction: String) {
    this.changeMonth.emit(direction)
  }

  resizeCalendar() {
    const cal = this.calendar.nativeElement
    const container = cal.parentElement

    if (container.offsetHeight <= container.offsetWidth) {
      container.style.flexDirection = 'column'
      cal.style.width = `${cal.offsetHeight}px`
      cal.style.height = '0'
    } else {
      container.style.flexDirection = 'row'
      cal.style.height = `${cal.offsetWidth}px`
      cal.style.width = '0'
    }
  }

  // Highlight the currrent day
  isSelected(day) {
    if (!day) return false

    return (this.selectedDay.format('L') === day.format('L'))
  }

  todayCheck(day) {
    if (!day) 
      return false;
    
    return moment().format('L') === day.format('L');
  }

  // fade out Days that are not in the current month
  differentMonth(month) {
    if (!month) return false

    // checks if the months are different
    return (!moment(this.today).isSame(month, 'M'))
  }

  createCalendar(month: Moment): Moment[] {
    const firstDay = moment(month).startOf('M')
    const startOfMonthPadding = firstDay.weekday()
    const endOfMonthPadding = 42 - (startOfMonthPadding + (month.daysInMonth()))

    // Adding the days of the current month to the calendar
    const days = Array.apply(null, {length: month.daysInMonth()})
      .map(Number.call, Number)
      .map((n) => {
        return (moment(firstDay)).add(n, 'd')
      })

    // Adding the days for the previous month at the beggening of the calendar
    for (let n = 0; n < startOfMonthPadding; n++) {
      days.unshift(moment(month).subtract(1, 'M').endOf('M').subtract(n, 'd'))
    }

    // Adding the days for the following month at the end of the calendar
    for (let n = 0; n < endOfMonthPadding; n++) {
      days.push(moment(month).add(1, 'M').startOf('M').add(n, 'd'))
    }

    return days
  }

  public nextMonth() {
    this.today.add(1, 'M')
    this.daysArr = this.createCalendar(this.today)

    this.changeSelectedMonth('next')
  }

  public previousMonth() {
    this.today.subtract(1, 'M')
    this.daysArr = this.createCalendar(this.today)

    this.changeSelectedMonth('previous')
  }

  eventCheck(day : Moment) {

    if (!day)
      return true

    //only pulls badges for the current month
    if (this.differentMonth(day) === false){

      let currentDay = day.format('D')
      this.count[currentDay] = 0

     

      if(!this.events)
        return true

      this.events.forEach(x => {
        if (moment(x.start).isSame(day, 'day'))
          this.count[currentDay]++
      })

      if (this.count[currentDay] > 0)
        return false

       else
        return true

    }
    else
      return true
    }
}
