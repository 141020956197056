import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { CalendarEvent } from '../../../../models/calendar-event'

@Component({
  selector: 'event-button',
  templateUrl: './event-button.component.html',
  styleUrls: ['./event-button.component.scss']
})
export class EventButtonComponent implements OnInit {
  @Input() event: CalendarEvent
  @Output() changeSelectedEvent = new EventEmitter<CalendarEvent>()
  eventTime

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.eventTime = this.getEventDate()
  }

  setSelected() {
    this.changeSelectedEvent.emit(this.event)
  }

  getEventDate() {
    const now = new Date()
    const eventDate = new Date(this.event.start)

    const deltaDay = eventDate.getDate() - now.getDate()
    const deltaHour = eventDate.getHours() - now.getHours()

    if (deltaDay == 0) {
      if (deltaHour > 0)
        return `${deltaHour}hrs`
      else if (deltaHour == 0)
        return 'Now'
      else
        return 'Past'
    } else if (deltaDay > 7)
      return `${Math.floor(deltaDay / 7)}wks`
    else if (deltaDay > 0)
      return `${deltaDay}dys`
    else
      return 'Past'
  }
}
