import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { HttpService } from '../../../services/http.service'
import { CalendarEvent } from '../../../models/calendar-event'
import { NavigationExtras, Router } from '@angular/router'
import * as moment from 'moment'
import { Moment } from 'moment'

const EVENT_VIEW_WINDOW_DAYS = 7

@Component({
  selector: 'events-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit, OnChanges {
  @Input() events: CalendarEvent[]

  displayedEvents: CalendarEvent[] = this.events

  @Input() selectedDay: Moment
  @Output() changeSelectedEvent = new EventEmitter<CalendarEvent>()

  constructor(private http: HttpService, private router: Router) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.filterEventsBySelectedDay()
  }

  setSelected(event: CalendarEvent) {
    this.changeSelectedEvent.emit(event)

    if (window.innerWidth < 750) {
      const navigationExtras: NavigationExtras = {
        queryParams: {'event': JSON.stringify(event)}
      }
      this.router.navigate(['/event-description'], navigationExtras)
    }
  }

  private filterEventsBySelectedDay() {
    if (!this.events) return

    this.displayedEvents = this.events.filter((event: CalendarEvent) => {
      const eventDate = moment(event.start)
      const displayWindowEndDate = this.selectedDay.clone().add(7, 'day')

      return eventDate.isBetween(this.selectedDay, displayWindowEndDate)
    })
  }
}
