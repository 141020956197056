export class PVector {
  x: number
  y: number

  constructor(x: number, y: number) {
    this.x = x
    this.y = y
  }

  add(pos: PVector): PVector {
    return new PVector(this.x + pos.x, this.y + pos.y)
  }

  subtract(pos: PVector): PVector {
    return new PVector(this.x - pos.x, this.y - pos.y)
  }

  mult(x: number): PVector {
    return new PVector(this.x * x, this.y * x)
  }

  clone(): PVector {
    return new PVector(this.x, this.y)
  }
}
