import { Comet } from './comet'
import { Star } from './star'

const fps = 30
const frameRate = 1000 / fps
let frames = 0, elapsedSeconds = 0, currentFps

export class GenerativeArt {
  canvas: HTMLCanvasElement
  ctx: CanvasRenderingContext2D
  elapsed = 0
  pastTime = Date.now()
  resolution = 1080
  stars = []
  comets = []

  constructor(canvasSelector: string) {
    this.canvas = document.querySelector(canvasSelector)
    this.ctx = this.canvas.getContext('2d')

    window.addEventListener("resize", () => this.resizeCanvas())

    this.resizeCanvas()
    this.setFrameRate()
  }

  private resizeCanvas(): void {
    const aspectRatio = this.canvas.parentElement.clientWidth/this.canvas.parentElement.clientHeight

    this.canvas.width = this.resolution*aspectRatio
    this.canvas.height = this.resolution

    this.canvas.style.width = `${this.canvas.parentElement.clientWidth}px`
    this.canvas.style.height = `${this.canvas.parentElement.clientHeight}px`
  }

  private setFrameRate(): void {
    requestAnimationFrame(() => this.setFrameRate())

    this.elapsed = Date.now() - this.pastTime

    if(elapsedSeconds > 10000)
    {
      currentFps = Math.round(1000*frames/elapsedSeconds)
      elapsedSeconds = 0
      frames = 0

      if(currentFps < fps-7)
        this.setCanvasQuality(-1)
    }

    if (this.elapsed >= frameRate) {
      this.pastTime = Date.now() - (this.elapsed % frameRate)

      elapsedSeconds += this.elapsed - this.elapsed % frameRate
      frames++

      this.update()
    }
  }

  private setCanvasQuality(quailtyChange): void {
    const resolutions = [480, 720, 1080]
    let index = -1

    for(let i = 0; i < resolutions.length; i++){
      if(this.resolution == resolutions[i])
      {
        index = i
        break
      }
    }

    if(index == -1)
      return

    index += quailtyChange
    if(index < 0 || index >= resolutions.length)
      return

    this.resolution = resolutions[index]
    this.resizeCanvas()
  }

  update(): void {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

    const randNum = Math.random()

    // Creates star based on probability
    if (randNum > .94) {
      this.stars.push(new Star(this.canvas, this.ctx))
    }

    // Creates comet based on probability
    if (randNum > .95) {
      this.comets.push(new Comet(this.canvas, this.ctx))
    }

    this.drawElements(this.stars)
    this.drawElements(this.comets)
  }

  private drawElements(arr) {
    // Goes through elements backwards to remove
    // any artifacting when removing elements from
    // the element array
    for (let i = arr.length - 1; i >= 0; i--) {
      arr[i].update()

      if (!arr[i].alive) {
        arr.splice(i, 1)
      }
    }
  }

}
