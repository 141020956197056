import { PVector } from './p-vector'
import { Color } from './color'
import { LIGHTBLUE, PINK, PINKRED, PURPLE, REDPINK, WHITE } from './colors'

const ALPHA_RATE = .008
const COLOR_PALETTE = [
  PINK,
  REDPINK,
  LIGHTBLUE,
  WHITE,
  PINKRED,
  PURPLE,
]

export class Star {
  canvas: HTMLCanvasElement
  ctx: CanvasRenderingContext2D
  position: PVector
  color: Color
  alpha = -1
  radius: number
  maxRadius = 30
  alive = true

  constructor(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) {
    this.canvas = canvas
    this.ctx = ctx
    this.radius = Math.random() * this.maxRadius + 10
    const x = Math.random() * canvas.width
    const y = Math.random() * canvas.height
    this.position = new PVector(x, y)

    const index = Math.floor(Math.random() * COLOR_PALETTE.length)
    this.color = COLOR_PALETTE[index]
  }

  draw() {
    const angle = 2.0 * Math.PI / 4.0
    const halfAngle = angle / 2.0
    this.ctx.beginPath()
    this.ctx.moveTo(this.position.x + this.radius / 2, this.position.y)
    for (let a = 0; a < 2 * Math.PI; a += angle) {
      let sx = this.position.x + Math.cos(a) * this.radius * 0.5
      let sy = this.position.y + Math.sin(a) * this.radius * 0.5
      this.ctx.lineTo(sx, sy)
      sx = this.position.x + Math.cos(a + halfAngle) * this.radius * 0.2
      sy = this.position.y + Math.sin(a + halfAngle) * this.radius * 0.2
      this.ctx.lineTo(sx, sy)
    }
    this.ctx.closePath()

    this.ctx.fillStyle = `rgb(${this.color.r}, ${this.color.g}, ${this.color.b}`
    this.ctx.globalAlpha = -Math.abs(this.alpha) + 1
    this.ctx.fill()
    this.ctx.globalAlpha = 1
  }

  update() {
    this.draw()

    if (this.alpha + ALPHA_RATE > 1) {
      this.alive = false
    } else {
      this.alpha += ALPHA_RATE
    }
  }
}
