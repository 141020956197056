import { Component, OnInit } from '@angular/core'
import { CalendarEvent } from '../../models/calendar-event'
import { HttpService } from '../../services/http.service'
import * as moment from 'moment'
import { Moment } from 'moment'

@Component({
  selector: 'event-view',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  events: CalendarEvent[] = []

  selectedDay: Moment = moment().startOf('day')
  selectedEvent: CalendarEvent = {
    title: '',
    description: '',
    attachments: [],
    start: new Date(),
    end: new Date()
  }

  constructor(private http: HttpService) {}

  ngOnInit() {
    this.refreshEvents()
  }

  refreshEvents() {
    const method = `GetEvents?month=${this.selectedDay.month()+1}&year=${this.selectedDay.year()}`
    this.http.get(method)
      .subscribe(resp  => {
        this.events = resp.data
        this.selectedEvent = this.getEventByDate(this.selectedDay)
      })
  }

  setSelectedEvent(event: CalendarEvent) {
    this.selectedEvent = event
  }

  setSelectedDay(day: Moment) {
    this.selectedDay = day
    this.setSelectedEvent(this.getEventByDate(day))
  }

  getEventByDate(date: Moment) {
    // Check for months other than date
    if (date.month() < this.selectedDay.month())
      return this.events[this.events.length-1]
    else if (date.month() > this.selectedDay.month())
      return this.events[0]

    // Gets next event relative to now
    for (let event of this.events) {
      let eventDate = moment(event.start)

      if (eventDate.isAfter(date))
        return event
    }

    // Always returns closest event to date
    return this.events[this.events.length-1]
  }

  setSelectedMonth(direction: String) {
    if (direction == 'next')
      this.selectedDay.add(1, 'month')
    else if (direction == 'previous')
      this.selectedDay.subtract(1, 'month')

    this.refreshEvents()
  }
}
