import { Component, OnInit } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { slider } from '../route-animations/route-animations'
import { GenerativeArt } from '../generative-art/generative-art'

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [slider]
})
export class MainComponent implements OnInit {
  generativeArt: GenerativeArt

  constructor() {
  }

  ngOnInit() {
    this.generativeArt = new GenerativeArt('canvas')
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation']
  }

}
