import { Component, Input, OnInit } from '@angular/core'
import { CalendarEvent } from '../../../models/calendar-event'
import { Router } from '@angular/router'

@Component({
  selector: 'event-description',
  templateUrl: './event-description.component.html',
  styleUrls: ['./event-description.component.scss']
})
export class EventDescriptionComponent implements OnInit {
  @Input() event: CalendarEvent
  @Input() backButton: boolean

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  goBack() {
    this.router.navigate([''])
  }

  getEventDate(): string {
    const eventStart = new Date(this.event.start)
    const eventEnd = new Date(this.event.end)
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    return `${months[eventStart.getMonth()]} ` +
      `${eventStart.getDate()} ` +
      `${eventStart.getFullYear()}, ` +
      `${eventStart.getHours() % 12}:` +
      `${(eventStart.getMinutes() === 0) ? '00' : eventStart.getMinutes()}` +
      `${(eventStart.getHours() > 12) ? 'PM' : 'AM'} - ` +
      `${eventEnd.getHours() % 12}:` +
      `${(eventEnd.getMinutes() === 0) ? '00' : eventEnd.getMinutes()}` +
      `${(eventEnd.getHours() > 12) ? 'PM' : 'AM'}`
  }
}
