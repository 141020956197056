import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'splash-page',
  templateUrl: './splash-page.component.html',
  styleUrls: ['./splash-page.component.scss']
})
export class SplashPageComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
