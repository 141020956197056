export class Color {
  r: number
  g: number
  b: number

  constructor(r: number, g: number, b: number) {
    this.r = r
    this.g = g
    this.b = b
  }

  static lerpColor(color1, color2, lerp) {
    let colorDiff = color2.subtract(color1)
    colorDiff = colorDiff.scalarMult(lerp)

    return color1.add(colorDiff)
  }

  add(color: Color) {
    return new Color(this.r + color.r, this.g + color.g, this.b + color.b)
  }

  subtract(color: Color) {
    return new Color(this.r - color.r, this.g - color.g, this.b - color.b)
  }

  scalarMult(x: number) {
    const r = Math.floor(this.r * x)
    const g = Math.floor(this.g * x)
    const b = Math.floor(this.b * x)

    return new Color(r, g, b)
  }
}
