import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'email-signup',
  templateUrl: './email-signup.component.html',
  styleUrls: ['./email-signup.component.scss']
})
export class EmailSignupComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
