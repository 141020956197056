import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { MainComponent } from './main/main.component'
import { SplashPageComponent } from './main/splash-page/splash-page.component'
import { EmailSignupComponent } from './main/email-signup/email-signup.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatButtonModule, MatDialogModule, MatInputModule } from '@angular/material'
import { SocialLinksComponent } from './main/email-signup/social-links/social-links.component';
import { CalendarComponent } from './main/events/calendar/calendar.component';
import { EventsComponent } from './main/events/events.component';
import { EventDescriptionComponent } from './main/events/event-description/event-description.component';
import { EventListComponent } from './main/events/event-list/event-list.component'
import { EventButtonComponent } from './main/events/event-list/event-button/event-button.component'
import { HttpClientModule } from '@angular/common/http'
import { HttpService } from './services/http.service';
import { EventAttachmentComponent } from './main/events/event-description/event-attachment/event-attachment.component';
import { EventDescriptionMobileComponent } from './main/events/event-description-mobile/event-description-mobile.component'

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    SplashPageComponent,
    EmailSignupComponent,
    SocialLinksComponent,
    CalendarComponent,
    EventsComponent,
    EventDescriptionComponent,
    EventListComponent,
    EventButtonComponent,
    EventAttachmentComponent,
    EventDescriptionMobileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
  ],
  providers: [
    HttpService,
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule { }
